import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import Loading from '~/components/Loading'
import {useRedirectToLastUrl} from "~/hooks/useRedirectToLastUrl";

const Index = () => {
  useRedirectToLastUrl()
  return <Loading />
}

const mapStateToProps = (state) => {
  return {
    user: state.authenticationReducer.user
  }
}

export default withRouter(connect(mapStateToProps)(Index))
